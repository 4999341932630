.Wrapper{
    width:100%;
    height: 100%;
    display: flex;
}

.FlexCol{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.ContentWrapper{
    flex: 1;
    position: relative;
    overflow: auto;
}