.SidebarHandle{
    width: 13px;
    height: 36px;
    position: absolute;
    right: -23px;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #fff;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    box-shadow: 4px 0px 5px rgba(2, 0, 0, 0.3);
}

.SidebarHandle svg{
    width: 6px;
    fill: #525252;
    margin-left: -2px;
}

.Open.SidebarHandle svg{
    transform: rotate(180deg);
}