.CloseButton{
    position: relative;
    opacity: 1;
    cursor: pointer;
    width: 15px;
    height: 15px;
}

.CloseButton:before, .CloseButton:after {
    position: absolute;
    left: calc(50% - 1px);
    content: ' ';
    height: 100%;
    width: 2px;
    background-color: #525252;
}
.CloseButton:before {
  transform: rotate(45deg);
}
.CloseButton:after {
  transform: rotate(-45deg);
}

.Red.CloseButton:before, .Red.CloseButton:after{
  background-color: #b82323;
}