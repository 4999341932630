.RequestID{
    color:#82AE2C;
}

.ContactDetails{
    margin-top:40px;
}

.ContactDetails > div{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.ContactDetails svg{
    width: 23px;
    max-height: 20px;
    fill: #525252;
    margin-right: 10px;
}

