.MenuButton {
    position: relative;
    width: 100%;
    height: 80px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font: inherit;
    font-size: 1em;
    padding: 0 20px;

    display: flex;
    align-items: center;

    transition: background-color 0.3s ease-in-out;
}

.MenuButton:disabled {
    color: #ccc;
    cursor: not-allowed;
    background: #aaa;
}

.MenuButton:disabled svg{
    fill: #ccc;  
}

.MenuButton svg{
    width:100%;
    max-width: 40px;
    max-height: 30px;
    fill:#82AE2C;
    margin-right: 20px;
}

.MenuButton span{
    font-weight: 600;
    color: #525252;
}

.MenuButton:hover{
    background-color:#fff;
}

.MenuButton.Active{
    background-color: #fff;
}

.MenuButton.Active:after{
    content:'';
    position:absolute;
    top: 0;
    right:-1px;
    height: 100%;
    width:1px;
    background:#fff;
}