.Root{
    width: 197px;
    position: relative;
}

.Root > span{
    color:#82ae2c;
    padding: 13px 0;
}

.AdditionalMarks{
    position: absolute;
    height: 12px;
    width: 100%;
    top: 7px;
}

.AdditionalMarks span{
    position: absolute;
    top: 1px;
    width: 2px;
    height: 100%;
    background-color: #aaa;
}

.AdditionalMarks span:nth-child(1){
    left: 0;
}
.AdditionalMarks span:nth-child(2){
    left: 50%;
}
.AdditionalMarks span:nth-child(3){
    left: 100%;
}

.BaseValue{
    width: 200px;
    margin: 0 auto;
    position: relative;
}

.UserValue{
    width: 100%;
}

.UserValueTitle{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
}

.UserValueTitle span{
    flex: 1;
    font-size: 0.9em;
}

.UserValueTitle svg{
    fill: #646464;
    width: 21px;
    cursor: pointer;
}

.UserInput{
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    background: #E6E6E6;
    border: solid 2px #cdcdcd;
    border-radius: 4px;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    font-size: 1.5em;
    font-weight: 600;
    color: #646464;
}

.UserInput:focus{
    outline:none;
}

.Error.UserInput{
    border: solid 1px red;
}

.VariableInfo{
    opacity: 0;
    width: 300px;
    position: absolute;
    top: 36px;
    left: -65px;
    padding: 15px;
    background: #82ae2c;
    color: #fff;
    z-index: 1;
    pointer-events: none;
    transition-property: opacity,transform;
    transition-duration: 350ms;
    transition-timing-function: cubic-bezier(.165,.84,.44,1);
}

.Show.VariableInfo{
    opacity: 1;
    transform: translateY(-10px);
    pointer-events: all;
}

.VariableInfo span{
    flex: 1;
    font-size: 0.9em;
}


.Root .Track {
    display: none;
}

.Root .Mark {
    height: 6px;
    top: 11px;
    background-color: #ccc;
}

.Root .MarkActive {
    background-color: #ccc;
}

.Root .MarkLabel {
    color: #646464;
    font-size: 0.9em;
    top: 25px;
}

.Root .Thumb {
    width: 16px;
    height: 16px;
    margin-top: -7px;
    margin-left: -7px;
    background-color: #82ae2c;
    z-index:1;
}

.Root .Thumb:hover{
    box-shadow: 0px 0px 0px 8px rgba(143, 199, 64, 0.16);
}

.Root .ValueLabel {
    left: -50%;
}
  
.Root .Rail {
    background-color: #ccc;
}


@media only screen and (max-width: 1000px) {
    .BaseValue{
        margin-bottom: 60px;
    }
}    