.InfoButton{
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #525252;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 0.8em;
}
