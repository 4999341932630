.Topbar{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #515252;
    color: #C1C1C1;
    fill: #C1C1C1;
    text-align: right;
    padding: 0 40px;
    z-index: 10000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    flex: 0 0 40px;
}


.Logout{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.Logout svg {
    width:22px;
    margin-left: 10px;
} 

.Welcome{

}

.Welcome > span{
    margin-right: 10px;
}

.Welcome .WelcomeUsername{
    color:#82AE2C;
    position: relative;
    padding-right: 15px;
    margin-right: 15px;
}

.WelcomeUsername::after{
    content: '';
    position: absolute;
    top: 4px;
    right: 0;
    width: 1px;
    height: 15px;
    background: #8f8f8f;
}