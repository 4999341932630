.Sidebar{
    background:#F2F2F2;
    z-index: 10000;
    position: relative;    
}

.SidebarInner{
    width:0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    transition: width 0.3s ease-in;
}

.Visible .SidebarInner{
    width:320px;
}

.Sidenav{
    flex:1;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Link{
    width: 100%;
    text-decoration: none;
}