.PanelDataItem{
    margin: 20px 0 0;
}

.TitleWrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #C5C5C5;
    margin-bottom: 30px;
    margin-left: -30px;
    margin-right: -30px;
    padding-bottom: 7px;
}

.TitleWrapper h3{
    font-size: 1em;
    font-weight: 400;
    color:#82AE2C;
    margin: 0;
    margin-right:20px;
}

.Info{
    width: 100%;
    position: absolute;
    top: 25px;
    left: 0;
    opacity: 0;
    background: #525252;
    font-weight: 300;
    color: #e6e6e6;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1;
    pointer-events: none;        
    transition: opacity 0.3s ease-in-out;
}

.NoTitle{
    margin:0;
}

.NoTitle .TitleWrapper{
    display: none;
} 

.ShowInfo .Info{
    opacity: 1;
}

