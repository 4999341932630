.PanelContentWrapper{
    padding: 20px 30px 30px;
}

.Margin{
    margin-top:40px;
}

/* Tabs */
.Tabs{
    height: 100%;
}

ul.TabList{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #82AE2C;
}

ul.TabList li{
    position: relative;
    width: 80px;
    display: flex;
    flex-direction: column;
    outline: none;
    align-items: center;
    justify-content: center;
    color: #EAFEC4;
    fill:#EAFEC4;
    padding: 10px 0;
    cursor: pointer;

    transition: background-color 0.3s ease-in-out;
    will-change: background-color;
}

ul.TabList li:hover::after,
.SelectedTab::after{
    content: '';
    position: absolute;
    bottom:-1px;
    left:0;
    width: 100%;
    height: 1px;
    background:#fff;
}

.IconWrapper{
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.TabList li svg{
    width:100%;
    max-width:26px;
    max-height: 26px;
    transition: fill 0.3s ease-in-out;
    will-change: fill;
}

ul.TabList li span{
    font-size: 0.9em;
    transition: color 0.3s ease-in-out;
    will-change: color;
}

ul.TabList li:hover{
    background-color:#fff;   
}

ul.TabList li:hover svg,
.SelectedTab svg{
    fill:#525252;
}

ul.TabList li:hover span,
.SelectedTab span{
    color:#525252;
}

.SelectedTab{
    background:#fff;
}

/* AOI */
.AOIItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.ActiveAOIItem{
    
}

.AOIItemContent{
    max-height: 0;
    width:100%;
    overflow: hidden;
    text-align: center;

    transition:max-height 0.3s ease-in-out;
}

.ActiveAOIItem .AOIItemContent{
    max-height: 500px;
}

.AOIItemContent > svg {
    width: 20px;
    margin-bottom: 20px;
}

.FlexRow{
    display: flex;
    align-items: center;
}
.FlexRowTop{
    display: flex;
    align-items: flex-start;
}

.NoTabs .TabList{
    display: none;
}

.NoTabs .PanelContentWrapper{
    padding: 20px 30px;
}

.AOISelectedWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AOISelected{
    width:160px;
    height: 140px;
    margin: 0 auto;
}

.SelectedTabPanel { 
    overflow: auto;
    height: calc(100% - 68px);
    
    animation-name: fadeIn; 
    animation-duration: 500ms; 
    animation-timing-function: linear; 
}

@keyframes 
    fadeIn { 
        from { opacity: 0; } 
        to { opacity: 1; } 
    }

.SubCategory{
    margin-bottom: 40px;
}

.SubCategory:last-child{
    margin-bottom: 0;
}

.SubTitle{
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 7px;
    margin: 0;
    margin-bottom: 10px;
    color: #82AE2C;
    font-weight: 400;
}

/* Purchase products */
.Step{
    margin-bottom:40px;
}

.Step:last-child{
    margin-bottom:0;
}

.StepNumber{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #F2F2F2;
    color: #525252;
}

.Complete .StepNumber{
    background: #82ae2c;
    color: #EAFEC4;
}

.PurchaseWrapper{
    display:flex;
    flex-direction:column;
    justify-content: center;
}

.StepNumberWrapper{
    flex: 0 0 40px;
}

.StepDescription{
    width: 235px;
}

.ProductSelection{

}

.DateSelection{
    display: flex;
    flex-direction: column;
}

.SelectComp{
    min-width: 200px;
    margin-bottom: 10px;
}

.SelectComp:last-child{
    margin-bottom:0
}

.SelectComp:focus{
    outline:none;
}

.SelectComp > div{
    border: none;
    border-bottom: solid 1px #c5c5c5;
    border-radius: 0;
}

.SelectComp span{
    border:none;
    outline:none;
}

.SelectComp > div:hover{
    border-color:#82ae2c;
}

.SelectComp > div > div:focus,
.SelectComp > div > div > div:focus{
    outline:none;
}

.SelectComp > div:nth-child(3){
    
}

.ValueBox{
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    border: solid 1px #C5C5C5;
    padding: 10px 20px;
    box-sizing: border-box;
}

.ValueBox span{
    font-size: 1.4em;
    font-weight: 600;
    margin-right: 20px;
}

.ValueBox svg{
    width: 17px;
    fill: #525252;
    margin-left: auto;
}

.ValueCalculated.ValueBox{
    border-color: #82ae2c;
}

.SelectCompPart{

}

.BasketNumWrapper{
    position: absolute;
    top: 4px;
    right: 14px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #525252;
    display: flex;
    align-items: center;
    justify-content: center;

    transition:background-color 0.3s ease-in-out;
}

.BasketNumWrapper span{
    font-size: 0.8em;
}

ul.TabList li:hover .BasketNumWrapper,
.SelectedTab .BasketNumWrapper{
    background-color:#82AE2C;
}
ul.TabList li:hover .BasketNumWrapper span,
.SelectedTab .BasketNumWrapper span{
    color:#EAFEC4;
}
