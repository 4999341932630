.BasketWrapper{

}

.BasketItemsWrapper{
    
}

.FlexBox{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom:10px;
}

.FlexBox .Col{margin-right:25px;}
.FlexBox .Col:last-child{margin-right:0;}

.FlexBox .Col1{flex: 0 0 5%;}
/* .FlexBox .Col2{flex: 1;} */
.FlexBox .Col3{flex: 1;}
.FlexBox .Col4{flex: 1; height: 100px; border: solid 1px #C5C5C5;}
.FlexBox .Col5{display: flex; flex: 0 0 100px;}
.FlexBox .Col6{flex: 0 0 5%;}

.FlexBox .TableHeader.Col4{flex: 1; height: auto; border:none}

.TableHeader{
    font-weight: 600;
}

.AOIWrapper{
    padding-right:10px;
}

.Col5 svg,
.SumValue svg{
    width: 10px;
    fill: #525252;
    margin-left: 10px;
}

.SumValueWrapper{
    /* width: calc(95% - 25px); */
    display: flex;
    margin-top:20px;
    padding-top:20px;
    border-top:solid 1px #82AE2C;
}

.SumValueLabel{
    font-weight: 600;
    flex: 1;
    margin-right: 25px;
}

.SumValue{
    font-weight: 600;
    margin-right: 76px;
    display: flex;
}

.PurchaseButtonWrapper{
    /* width: calc(95% - 25px); */
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}

.Message{
    overflow: hidden;
    width:100%;
    height:0;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;

    transition-property: height;
    transition-duration: 350ms;
    transition-timing-function: cubic-bezier(.165,.84,.44,1);
}
.Error.Message{
    height:54px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}