input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.SearchBoxWrapper{
    margin: 50px 20px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02);
    background: #fff;
}

.ShowSearchResults.SearchBoxWrapper{
    border-radius: 8px 8px 0 0;
}

.SearchBoxInner{
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding-left: 17px;
}

.SearchBox{
    flex: 1 1;
    outline: none;
    border: none;
    font-family: inherit;
    font-size: 0.9em;
    color: #525252;
    min-width: 50px;
}

.SearchBox::placeholder,
.SearchBox:-ms-input-placeholder,
.SearchBox::-ms-input-placeholder{
    color: #d8d8d8;
    opacity:1;
}

.SearchButtonWrapper,
.ClearSearchButtonWrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 5px;
    cursor: pointer;
    flex: 0 0 36px;
}

.SearchButtonWrapper::after{
    content: "";
    position: absolute;
    right: 0;
    top: 8px;
    border-left: 1px solid #ddd;
    height: 28px;
    flex: 0 0 36px;
}

.SearchButton,
.ClearSearchButton{
    background: transparent;
    border:none;
    outline: none;
}

.ClearSearchButton{
    position: absolute;
}

.SearchButton::before,
.ClearSearchButton::before{
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.SearchButton::before{
    background: url(../../assets/images/searchIcon.png);
    background-size: 72px 24px;
}

.ClearSearchButton::before{
    background-image: url(../../assets/images/clearIcon.png);
    background-size: 96px 24px;
}

.ResultsWrapper{
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    font-size: 15px;
    overflow: hidden;
    padding: 8px 0;
    border-top: 1px solid #DADCE0;
    position: absolute;
    width: calc(100% - 40px);
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02);
}

.ResultsItems{
    background: transparent;
    border: 0;
    border-radius: 0;
    font: inherit;
    list-style: none;
    margin: 0;
    outline: 0;
    overflow: visible;
    padding: 0;
    vertical-align: baseline;
}

.ResultItem{
    display: flex;
    position: relative;
    font-size: 0.9em;
    line-height: 24px;
    min-height: 24px;
    cursor: pointer;
    padding: 6px 20px;
}

.ResultItem:hover{
    background-color: rgba(0, 0, 0, .06);
}

.NoResultItem{
    position: relative;
    font-size: 0.9em;
    line-height: 24px;
    min-height: 24px;
    padding: 6px 20px;
}

.Hide.NoResultItem{
    display: none;
}

.ResultItem svg{
    flex: 0 0 10px;
    margin-right: 5px;
    fill: #525252;
}

.ResultItem span{
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}