.PanelContentWrapper{
    padding: 20px 30px 30px;
}

/* Tabs */
.Tabs{
    height: 100%;
}

ul.TabList{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #82AE2C;
}

ul.TabList li{
    position: relative;
    width: 80px;
    display: flex;
    flex-direction: column;
    outline: none;
    align-items: center;
    justify-content: center;
    color: #EAFEC4;
    fill:#EAFEC4;
    padding: 10px 0;
    cursor: pointer;

    transition: background-color 0.3s ease-in-out;
    will-change: background-color;
}

ul.TabList li:hover::after,
.SelectedTab::after{
    content: '';
    position: absolute;
    bottom:-1px;
    left:0;
    width: 100%;
    height: 1px;
    background:#fff;
}

.IconWrapper{
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.TabList li svg{
    width:100%;
    max-width:26px;
    max-height: 26px;
    transition: fill 0.3s ease-in-out;
    will-change: fill;
}

ul.TabList li span{
    font-size: 0.9em;
    transition: color 0.3s ease-in-out;
    will-change: color;
}

ul.TabList li:hover{
    background-color:#fff;   
}

ul.TabList li:hover svg,
.SelectedTab svg{
    fill:#525252;
}

ul.TabList li:hover span,
.SelectedTab span{
    color:#525252;
}

.SelectedTab{
    background:#fff;
}

/* AOI */
.AOIItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.AOIItemContent{
    width:100%;
    max-height: 0;
    text-align: center;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.ActiveAOIItem .AOIItemContent{
    max-height: 500px;
}

.AOIItemContent > svg {
    width: 20px;
    margin-bottom: 20px;
}

.AOIItem:first-child .AOIItemContent > svg {
    margin-bottom: 0;
}

.FlexRow{
    display: flex;
    align-items: center;
    justify-content: center;
}
.FlexRowLeft{
    display: flex;
    align-items: center;
}

.NoTabs .TabList{
    display: none;
}

.NoTabs .PanelContentWrapper{
    padding: 20px 30px;
}

.AOISelectedWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.AOISelectedWrapperSmall{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}

.AOISelected{
    width:200px;
    height: 200px;
    margin: 0 auto;
}

.AOISelectedWrapperSmall .AOISelected{
    width:160px;
    height: 140px;
    margin: 0;
}

.SelectedTabPanel { 
    overflow: auto;
    height: calc(100% - 68px);

    animation-name: fadeIn; 
    animation-duration: 500ms; 
    animation-timing-function: linear; 
}

@keyframes 
    fadeIn { 
        from { opacity: 0; } 
        to { opacity: 1; } 
    }

.SubCategory{
    margin-bottom: 40px;
}

.SubCategory:last-child{
    margin-bottom: 0;
}

.SubTitle{
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 7px;
    margin: 0;
    margin-bottom: 10px;
    color: #82AE2C;
    font-weight: 400;
}

.AlignRight{
    display: flex;
    justify-content: flex-end;
}

/* Printing */
.PrintOptions{

}

.PrintOptions h4{
    margin:0 0 10px 0;
}

.PrintSize,
.PrintOrientation{
    display: flex;
    align-items: center; 
    margin-bottom: 40px;; 
}

.PrintOption{
    margin-right: 30px;
    cursor: pointer;  
}

.PrintOption > span{
    display: block;
    text-align: center;
    margin-bottom: 5px;
}

.PrintOption > div{
    border: solid 1px #C5C5C5;
    background-color: #fff;

    transition:background-color 0.5s ease-in-out;
}

.Active.PrintOption > div{
    background-color: #F2F2F2;
    border-color: #82AE2C;
}

.A4PaperSize > div{
    width: 56px;
    height: 79px;
}

.A3PaperSize > div{
    width:71px;
    height: 112px;
}

.CustomPaperSize > div{
    width: 56px;
    height: 79px;
    border: dashed 1px #C5C5C5;
}

.PortraitOrientation > div{
    width: 56px;
    height: 79px;
}

.LandscapeOrientation > div{
    width: 79px;
    height: 56px;
}

.PrintButton{
    display: flex;
    justify-content: flex-end;
}

/* Queries */
.CreateQuery{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SaveButtonWrapper{
    margin-top: 30px;
}

.QueryResults{
    display: none;
    position:relative;
}

.Show.QueryResults{
    display: block;
}

.QueryResultsTitle{
    margin-bottom: 3px;
    padding-bottom: 3px;
    border-bottom:solid 1px #707070;
    margin-top: 30px;
}

.QueryLayersList{
    position:relative;
}

.SortList{
    list-style: none;
    box-shadow: 0px 0px 4px 0px rgba(136,136,136,1);
    background: #fff;
    left:30px!important;
    margin-top: -166px!important;
}


/* Swipe */
.SwitchButtonWrapper{
    display:flex;
    align-items: center;
}

.SwitchButtonWrapper > span{
    margin-left:10px;
}

.SwipeSelectWrapper > span{
    margin-top: 30px;
    display: block;
    font-weight: 600;
}

/* Grazing calculator */
.GrazingSelectionsWrapper{
    display: flex;
}

.GrazingSelectionsWrapper > .SubCategory{
    flex: 1;
    margin-right: 20px;
}

.YearSeasonWrapper{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    flex: 1;
}

.GrazingSubCategory{
    flex:1;
}

.GrazingSubCategory > div > div {
    margin-left: 0;
}

.GrazingResult {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    position: relative;
}

.GrazingResultSheep,
.GrazingResultCattle{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 125px;
    padding: 5px;
    border: solid 1px #C5C5C5;
    border-radius: 3px;
    margin-top: 10px;
}

.GrazingResultCattle{
    margin-left: auto;
}

.GrazingResultIcon{
    fill: #333;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GrazingResultSheep svg{
    width: 45px;
}

.GrazingResultCattle svg{
    width: 75px;
}

.GrazingResultValue{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GrazingResultValue > span:first-child{
    font-size: 1.4em;
    color: #82AE2C;
    font-weight: 600;
}

.GrazingButtonWrapper{
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

.SpinnerWrapper{
    opacity:0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #525252;
    pointer-events: none;

    transition:opacity 0.3s ease-in;
}

.Show.SpinnerWrapper{
    opacity:1;
}

@media only screen and (max-width: 780px) {
    .PanelContentWrapper{
        padding: 20px;
    }
}