.Panel{
    position: absolute;
    top: 126px;
    bottom:0;
    left: 0;
    width:100%;
    max-width:550px;
    z-index: 1001;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    webkit-transform: translateX(-110%);
			transform: translateX(-110%);

    transition: transform 0.3s linear, max-width 0.3s linear, top 0.3s linear;
    will-change: transform;
}

.PanelFullScreen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: unset;
    z-index: 100;
    box-sizing: border-box;
}

.Panel.Show{
    webkit-transform: translateX(0);
			transform: translateX(0);
}

.PanelContent{
    height: 100%;
    overflow: auto;
}

.CloseButtonWrapper{
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -16px;
    right: -11px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
    z-index: 1;
}


.DrawForm{
    top: unset;
}

.DrawForm .CloseButtonWrapper{
    display: none;
}

.FullWidthForm{
    max-width: 100%;
}

.FullWidthForm .CloseButtonWrapper{
    right: 0;
}

.FullHeightForm{
    top: 0;
}

.FullHeightForm .CloseButtonWrapper{
    top: 0;
}