ul{
    padding: 0;
    margin:0;
    list-style: none;
}

.FlexRow {
    display: flex;
    position: relative;
}

.FlexRowCenter{
    display: flex;
    position: relative;
    align-items: center;
}

.FlexRowButtons{
    display: flex;
    margin-bottom: 6px;
}
 
.LayerItemSymbol{
    max-width: 20px;
    max-height: 20px;
    margin-right: 10px;
    flex: 0 0 20px;
}

.LayerItemTools{
    display: flex;
    align-items: center;
    margin-left: auto;
}

.LayerItemTool{
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    border: solid 1px #AAAAAA;
    border-radius: 3px;
    cursor: pointer;
    background-color:#fff;

    transition: background-color 0.3s ease-in;
}

.Active.LayerItemTool,
.LayerItemTool:hover{
    background-color: #94bf86;
}

.LayerItemTool svg{
    width: 17px;
    max-height: 17px;
}

.LayerStyling{
    width: 100%;
    max-width: 500px;
    height: 0;
    overflow: hidden;

    transition:height, margin-top 0.3s ease-in-out;
}

.Active.LayerStyling{
    width: 100%;
    max-width: 500px;
    height: 105px;
    margin-top: 10px;
}

.FlexTableRow{
    display: flex;
    flex-flow: row wrap;
    border-left: solid 1px #d9d9d9;
    transition: 0.5s;
    box-sizing: border-box;
}

.FlexTableRow:first-child{
    border-top: solid 1px #d9d9d9;
}

.FlexCell1,
.FlexCell2{
    width: calc(100% / 2);
    text-align: center;
    padding: 0.5em 0.5em;
    border-right: solid 1px #d9d9d9;
    border-bottom: solid 1px #d9d9d9;
    box-sizing: border-box;
}

.LayerItem{
    flex:1;
    display: flex;
    flex-direction:column;
    margin-bottom: 0;
}

.LayerItemWrapper{
    padding: 10px 0;
}
.LayerItemTitle{
    font-weight: 600;
    flex: 1;
    align-self: center;
    font-size: 0.9em;
}

.DragHandle{
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 5px;
    cursor: move;
    background-color:#fff;
}

.DragHandle svg{
    width: 17px;
    max-height: 19px;
    fill:#cdcdcd;

    transition: fill 0.3s ease-in;
}

.DragHandle:hover svg{
    fill:#333;
}

.LayerItemSymbolImage{
    display: flex;
    align-items: center;
}

.LayerItemSymbolWrapper{
    height: 0;
    overflow: hidden;
}

.Active.LayerItemSymbolWrapper{
    height: auto;
}

.LayerOpacity{
    display: none;
    position: absolute;
    border: solid 1px #707070;
    padding: 5px 25px 0;
    top: 32px;
    right: 0;
    z-index: 1;
    background: #fff;
    box-shadow: 0px 0px 4px 0px #888888;
}

.Active.LayerOpacity{
    display: block;
}

.LayerOpacity > span{
    font-size: 0.9em;
    font-weight: 600;
}