.Logo {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    text-align:center;
    padding: 20px 60px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Logo img {
    max-width: 100%;
}