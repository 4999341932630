.AOISelectedWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AOISelected{
    width:160px;
    height: 140px;
    margin: 0 auto;
}

.LayerSelection{
    text-align: center;
}

.LayerSelection > span{
    font-weight: 600;
}

.GetStatisticsButtonWrapper{
    display: flex;
    justify-content: center;
    margin:30px 0;
}

.StatisticsResults{
    position: relative;
    margin-top: 46px;
}

.StatsRow{
    display: flex;
    align-items: center;
    justify-content: center;
}

.StatsRow > h4{
    flex: 1 1;
    padding-right: 10px;
    background: #525252;
    color: #C1C1C1;
    margin: 0;
    border: solid 1px #fff;
    font-weight: 400;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.StatsRow > span{
    flex: 1 1;
    padding-left: 10px;
    background: #F2F2F2;
    margin: 0;
    border: solid 1px #fff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.SpinnerWrapper{
    opacity:0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #525252;
    pointer-events: none;

    transition:opacity 0.3s ease-in;
}

.Show.SpinnerWrapper{
    opacity:1;
}