.PanelContentWrapper{
    padding: 20px 30px 30px;
}

/* Tabs */
.Tabs{
    height: 100%;
}

ul.TabList{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #82AE2C;
}

ul.TabList li{
    position: relative;
    width: 80px;
    display: flex;
    flex-direction: column;
    outline: none;
    align-items: center;
    justify-content: center;
    color: #EAFEC4;
    fill:#EAFEC4;
    padding: 10px 0;
    cursor: pointer;

    transition: background-color 0.3s ease-in-out;
    will-change: background-color;
}

ul.TabList li:hover::after,
.SelectedTab::after{
    content: '';
    position: absolute;
    bottom:-1px;
    left:0;
    width: 100%;
    height: 1px;
    background:#fff;
}

.IconWrapper{
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.TabList li svg{
    width:100%;
    max-width:26px;
    max-height: 26px;
    transition: fill 0.3s ease-in-out;
    will-change: fill;
}

ul.TabList li span{
    font-size: 0.9em;
    transition: color 0.3s ease-in-out;
    will-change: color;
}

ul.TabList li:hover{
    background-color:#fff;   
}

ul.TabList li:hover svg,
.SelectedTab svg{
    fill:#525252;
}

ul.TabList li:hover span,
.SelectedTab span{
    color:#525252;
}

.SelectedTab{
    background:#fff;
}

/* AOI */
.AOIItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.ActiveAOIItem{
    
}

.AOIItemContent{
    max-height: 0;
    width:100%;
    overflow: hidden;
    text-align: center;

    transition:max-height 0.3s ease-in-out;
}

.ActiveAOIItem .AOIItemContent{
    max-height: 500px;
}

.AOIItemContent > svg {
    width: 20px;
    margin-bottom: 20px;
}

.FlexRow{
    display: flex;
    align-items: center;
    justify-content: center;
}

.NoTabs .TabList{
    display: none;
}

.NoTabs .PanelContentWrapper{
    padding: 20px 30px;
}

.AOISelectedWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.AOISelected{
    width:200px;
    height: 200px;
    margin: 0 auto;
}

.SelectedTabPanel { 
    overflow: auto;
    height: calc(100% - 68px);
    
    animation-name: fadeIn; 
    animation-duration: 500ms; 
    animation-timing-function: linear; 
}

@keyframes 
    fadeIn { 
        from { opacity: 0; } 
        to { opacity: 1; } 
    }

.SubCategory{
    margin-bottom: 40px;
}

.SubCategory:last-child{
    margin-bottom: 0;
}

.SubTitle{
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 7px;
    margin: 0;
    margin-bottom: 10px;
    color: #82AE2C;
    font-weight: 400;
}

.SelectAOIMessage{
    display: flex;
    align-items: center;
}

.SelectAOIMessage > span {
    margin-right: 10px;
}