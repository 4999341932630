.CheckboxWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
}

.Checkbox{
    display: none;
}

.Checkbox + span{
    display: flex;
    width: 24px;
    height: 24px;
    background: #000;
    align-items: center;
    justify-content: center;
    color: white;
    border: 0px solid #82AE2C;
    box-sizing: border-box;
    transition: all 150ms;
}

.Checkbox + span + svg {
    display: none;
    width: 27px;
    margin-top: -26px;
    margin-left: -1px;
    position: absolute;
}

.Checkbox:checked + span{
    border-width: 12px;
}

.Checkbox:checked + span + svg{
    display: block;
}

/* 
.Checkbox:checked + span:before{
    content: 'check';
    font-family: 'Material Icons';
    font-size: 40px;
    color: #000;
} */

.Checkbox:disabled + span{
    background: rgba(0, 0, 0, .35);
}