.Dropzone_outer{
    display: flex;
    flex-direction: column;
}

.Dropzone_inner{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Dropzone {
    height: 200px;
    width: 200px;
    background-color: #fff;
    border: 2px dashed #aaa;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}
  
.Highlight {
    background-color: #94bf86;
    color: #fff;
    fill: #fff;
}

.Highlight .UploadLabel{
    color: #fff;
}

.UploadIcon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
    pointer-events: none;
}

.UploadLabel{
    pointer-events: none;
    color: #333;
}

.FileInput {
    display: none;
}

.FileList{
    width: 100%;
    flex:1;
    box-sizing: border-box;
    padding: 0;
}

.FileItem{
    margin: 15px 0;
}

.FileProgressBarBackground{
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background: #e6e6e6;
    margin-top: 5px;
    position: relative;
}

.FileProgressBar{
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 6px;
    border-radius: 3px;
    background: #94bf86;
}

.Dropzone_outer > .FlexRow button{
    display: none;
    margin-left: auto;
    font-size: 1em;
    color: #fff;
    font-weight: 400;
}

.Dropzone_outer.FilesAdded button{
    display: block;
}

.Dropzone_outer.FilesAdded .FileLoadedWrapper button{
    position: absolute;
    pointer-events: all;
}

.FileItemNameRow{
    display: flex;
    align-items: center;
}

.FileItemNameRow span{
    flex-grow: 1;
    text-align: left;
}

.FileLoadedWrapper{
    width: 30px;
    height: 30px;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
    pointer-events: none;
}

.FileLoadedCheck{
    transform: rotateY(180deg);
}

.Dropzone_outer.FilesAdded .FileLoadedWrapper.FileLoadedCheck button{
    pointer-events: none;
}

.FileLoadedIcon{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #94bf86;
    border-radius: 50%;
    position: absolute;
    transform: rotateY(180deg);
}

.FlexRow{
    display: flex;
    
}

.ClearButtonWrapper{
    margin-left: auto;
    flex: 1;
    margin-right: 5px;
}


@media (min-width: 991px) {
    /* .FileList{
        padding-left: 30px;
    }
  
    .Dropzone_inner{
        flex-direction: row;
    } */
}