.DownloadItem{
    width: 46%;
    border: solid 1px #707070;
    margin: 1.3%;
}

/* Tabs */
.Tabs{
    height: 100%;
}

ul.TabList{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #82AE2C;
}

ul.TabList li{
    position: relative;
    width: 80px;
    display: flex;
    flex-direction: column;
    outline: none;
    align-items: center;
    justify-content: center;
    color: #EAFEC4;
    fill:#EAFEC4;
    padding: 10px;
    cursor: pointer;

    transition: background-color 0.3s ease-in-out;
    will-change: background-color;
}

ul.TabList li:hover::after,
.SelectedTab::after{
    content: '';
    position: absolute;
    bottom:-1px;
    left:0;
    width: 100%;
    height: 1px;
    background:#fff;
}

ul.TabList li span{
    transition: color 0.3s ease-in-out;
    will-change: color;
}

ul.TabList li:hover{
    background-color:#fff;   
}

ul.TabList li:hover span,
.SelectedTab span{
    color:#525252;
}

.SelectedTab{
    background:#fff;
}

.PanelContentWrapper{
    padding: 20px 30px 30px;
}
.PanelContentWrapper h3{
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Item */
.TitleRow{
    display: flex;
    align-items: center;
}

.TitleRow svg{
    width: 20px;
    fill: #82AE2C;
    margin-right: 10px;
}

.TitleRow span{
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Description p{
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative; 
    /* use this value to count block height */
    line-height: 1.2em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 3.6em; 
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;

    margin-right: -1em;
    padding-right: 1em;
}

.Description p:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
}

.Description p:after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    width: 1em;
    /* set width and height */
    height: 1em;
    margin-top: 0.2em;
    background: #fff;
}

.Format{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    padding: 6px;
    border: solid 1px #707070;
    cursor: pointer;
    text-decoration: none;
}

.Format svg{
    width: 16px;
    margin-right: 10px;
    fill: #525252;
}

.LastUpdate{
    margin-top:1em;
    text-align: right;
}

.BoundariesMap{
    width:200px;
    height: 200px;
    margin: 0 auto;
}

.Metadata h4{
    margin-bottom: 10px;
}

.MetadataTable{

}

.MetadataTable li{
    display: table;
    width: 100%;
}

.MetadataTable li div {
    border: solid 2px #fff;
    padding: 10px;
    display: table-cell;
    width: 50%;
    vertical-align: middle;
}

.MetadataTable li div:nth-child(1){
    background: #515252;
    color: #C1C1C1;
}

.MetadataTable li div:nth-child(2){
    background: #F2F2F2;
    color: #525252;
}