.AdminWrapper{
    padding:30px;
}

.AvailableTitle{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.AvailableTitle > span{
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 7px;
    margin: 0;
    color: #82AE2C;
    font-weight: 400;
    margin-right: 30px;
}

.InputWrapper{
    background: #fff;
    height: 40px;
    border: solid 1px #b2b2b2;
    border-radius: 1px;
    display: flex;
    align-items: center;
    flex:1;
}

.InputWrapper svg{
    width:100%;
    max-width:19px;
    max-height: 19px;
    fill:#525252;
}

.InputWrapper input{
    height: 34px;
    border: none;
    flex: 1;
    outline: none;
    font-size: 1em;
    color: #525252;
    padding: 0 10px;
}

.InputWrapper > div{
    text-align: center;
    flex: 0 0 56px;
}

.InputCheckWrapper{
    display: flex;
    align-items: center;
    flex: 1;
}

.InputCheckWrapper input{
    border: solid 1px #525252;
    outline: none;
    margin-bottom: 1px;
}

.InputCheckWrapper span{
    font-weight: 600;
    margin-left: 10px;
}

.GeoserverUrl{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.GeoserverUrl > span {
    margin-right: 30px;
}

div[role=columnheader]{
    background: #82AE2C;
    color: #EAFEC4;
    padding: 7px 5px;
}

.SaveWrapper{
    margin-top: 40px;
}