.Map{
    height: 100%;
}

.Drawing.Map{
    cursor: crosshair;
}

.PanelContentWrapper{
    padding: 20px 30px 30px;
}

.Hidden{
    display: none;
}

/* Tabs */
.Tabs{
    height: 100%;
}

ul.TabList{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #82AE2C;
}

ul.TabList li{
    position: relative;
    width: 80px;
    display: flex;
    flex-direction: column;
    outline: none;
    align-items: center;
    justify-content: center;
    color: #EAFEC4;
    fill:#EAFEC4;
    padding: 10px 0;
    cursor: pointer;

    transition: background-color 0.3s ease-in-out;
    will-change: background-color;
}

ul.TabList li:hover::after,
.SelectedTab::after{
    content: '';
    position: absolute;
    bottom:-1px;
    left:0;
    width: 100%;
    height: 1px;
    background:#fff;
}

ul.TabList li svg{
    width:100%;
    max-width:26px;
    max-height: 26px;
    transition: fill 0.3s ease-in-out;
    will-change: fill;
}

ul.TabList li span{
    font-size: 0.9em;
    transition: color 0.3s ease-in-out;
    will-change: color;
}

ul.TabList li:hover{
    background-color:#fff;   
}

ul.TabList li:hover svg,
.SelectedTab svg{
    fill:#525252;
}

ul.TabList li:hover span,
.SelectedTab span{
    color:#525252;
}

.SelectedTab{
    background:#fff;
}

/* AOI */
.AOIItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.ActiveAOIItem{
    
}

.AOIItemContent{
    max-height: 0;
    width:100%;
    overflow: hidden;
    text-align: center;

    transition:max-height 0.3s ease-in-out;
}

.ActiveAOIItem .AOIItemContent{
    max-height: 500px;
}

.AOIItemContent > svg {
    width: 20px;
    margin-bottom: 20px;
}


.FlexRow{
    display: flex;
    align-items: center;
    justify-content: center;
}

.NoTabs .TabList{
    display: none;
}

.NoTabs .PanelContentWrapper{
    padding: 20px 30px;
}

.AOISelectedWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.AOISelected{
    width:200px;
    height: 200px;
    margin: 0 auto;
}

.SelectedTabPanel { 
    overflow: auto;
    height: calc(100% - 68px);
    
    animation-name: fadeIn; 
    animation-duration: 500ms; 
    animation-timing-function: linear; 
}


@keyframes 
    fadeIn { 
        from { opacity: 0; } 
        to { opacity: 1; } 
    }

.SubCategory{
    margin-bottom: 40px;
}

.SubCategory:last-child{
    margin-bottom: 0;
}

.SubTitle{
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 7px;
    margin: 0;
    margin-bottom: 10px;
    color: #82AE2C;
    font-weight: 400;
}

/* Popup */
.Popup{
    width: 300px;
}

.Popup > div:first-child{
    border-radius: 5px;
    padding:0;
    width: 100%;
}

.Popup > a{
    display: none;
}

.Popup > div:first-child > div{
    width: 100%;
    margin:0;
}

.ToolRow{
    height: 32px;
    display: flex;
    align-items: center;
    background: #82ae2c;
    border-radius: 3px 3px 0 0;
}

.ToolRowNav,
.ToolRowActions{
    flex: 1;
    display: flex;
    align-items: center;
}

.ToolRowNavSvg {
    width: 10px;
    fill: #eafec4;
    padding: 0px 10px;
    cursor: pointer;
}

.Disabled.ToolRowNavSvg{
    cursor: default;
    pointer-events: none;
    fill: #748d44;
}

.ToolRowNav > span{
    font-size: 1.2em;
    color: #eafec4;
}

.ToolRowActions svg{
    width: 17px;
    padding: 0px 10px;
    cursor: pointer;
    fill: #eafec4;
}

.ToolRowActions{
    justify-content: flex-end;
    padding-right: 10px;
}

.CloseButtonWrapper > div:before,
.CloseButtonWrapper > div:after{
    background-color: #eafec4;
}

.PopupContentWrapper{
    padding: 10px;
    font-size: 1.2em;
    background: #F2F2F2;
    overflow: hidden;
    border-radius: 0 0 5px 5px;
    overflow: auto;
    max-height: 500px;
}

.FeaturePanel{
    display: none;
    width: 100%;
    color: #525252;
}

.Active.FeaturePanel{
    display: block;
}

.LayerName{
    margin: 0;
    margin-bottom: 17px;
    padding-bottom: 6px;
    border-bottom: solid 1px #525252;
}

.FeaturePanel ul{
    display: table;
    width: 100%;
}

.FeaturePanel li{
    display: table-row;
}

.FeaturePanel span{
    display: table-cell;
    padding: 1px 5px;
    width: 50%;
}

.FeaturePanel li > span:nth-child(1){
    text-align: right;
    font-weight: 600; 
}


.SpinnerWrapper{
    padding:0 10px;
}

.Popup > div:nth-child(2) > div{
    background: #f2f2f2;
}

.PopupFullScreenWrapper{
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.PopupFullScreen{
    width: calc(100% - 60px);
    height: calc(100% - 20px);
    position: absolute;
    left: 30px;
    top: 10px;
    background: #fff;
    z-index: 100000;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.PopupFullScreen .PopupContentWrapper{
    height: calc(100% - 32px);
    box-sizing: border-box;
    max-height:unset;
}

.PopupFullScreen .ToolRowNav > span,
.PopupFullScreen .LayerName,
.PopupFullScreen li{
    font-size: 0.834em;
}

.Show.PopupFullScreenWrapper{
    display: block;
}

.Curtain{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    position: absolute;
    z-index: 1000;
}

.MapPopupFullScreen .Popup{
    opacity: 0!important;
    pointer-events: none!important;
}

/* Sidemap */
.SyncWrapper{
    width:100%;
    height: 100%;;
}

.SideMap{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    height:100%;
}

.ResizeHandleWrapper{
    position: absolute;
    top: 0;
    width: 6px;
    height: 100%;
    background: #fff;
    z-index: 1000;
}

.ResizeHandle{
    position: absolute;
    right: -10px;
    top: 50%;
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 1000;
    cursor: e-resize;
}

.noActions{
    pointer-events: none;
}