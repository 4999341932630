.Spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #82AE2C;
  background: -moz-linear-gradient(left, #82AE2C 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #82AE2C 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #82AE2C 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #82AE2C 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #82AE2C 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.Spinner:before {
  width: 50%;
  height: 50%;
  background: #82AE2C;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.Spinner:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ThemeLight.Spinner{
  background: #eafec4;
  background: -moz-linear-gradient(left, #eafec4 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #eafec4 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #eafec4 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #eafec4 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #eafec4 10%, rgba(255, 255, 255, 0) 42%);
}

.ThemeLight.Spinner:before{
  background: #eafec4;
}

.ThemeLight.Spinner:after{
  background: #82AE2C;
}

.ThemeDark.Spinner:after{
  background:#525252;
}


@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}