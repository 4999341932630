.Button {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    font-size: 1em;
    font-weight: 600;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
    background: #aaa;
}

.Button:disabled svg{
    fill: #ccc;  
}

.Green {
    background:#94bf86;
    color:#333;
    fill:#333;
}

.Red {
    background:#dc5858;
    color:#333;
    fill:#333;
}

.Circle {
    width:30px;
    height:30px;
    border-radius: 50%;
}

.Rectangle{
    width:80px;
    height:30px;
}

.MaxSize{
    width:100%;
    padding: 10px 0;
    border:solid 1px #AAAAAA;
    font-size: 1.5em;
}

.Large{
    padding: 10px;
    border:solid 1px #AAAAAA;
    font-size: 1.2em;
}

.Button svg{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.AlignRight{
    margin-left:auto;
    display: block;
}

.AlignCenter{
    margin:auto;
}

.Hidden{
    display: none;
}

.FontWhite{
    color:#fff;
    fill: #fff;
}

.FontRegular{
    font-weight: 400;
}

.backButton{
    padding: 10px 20px;
    margin-bottom: 20px;
}