.Button {
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    font: inherit;
    font-size: 1em;
    padding: 10px 20px;
    background-color: #F2F2F2;
    color:#525252;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color 0.3s ease-in-out;
}

.Button svg{
    width:100%;
    max-width: 20px;
    max-height: 20px;
    margin-right: 12px;
    fill:#525252;
    transition: fill 0.3s ease-in-out;
}
.Button span{
    
    transition: color 0.3s ease-in-out;
}

.Button:hover,
.Button.Active{
    background-color:#82AE2C;
}
.Button:hover svg,
.Button.Active svg{
    fill:#EAFEC4;
}
.Button:hover span,
.Button.Active span{
    color:#EAFEC4;
}

.Button.Active{
    background-color: #82AE2C;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
    background: #aaa;
}

.Button:disabled svg,
.Button:disabled:hover span,
.Button:disabled:hover svg{
    fill: #ccc;
    color: #ccc;
}

.InRow{
    margin:0 5px;
}


@media only screen and (max-width: 780px) {
    .Button{
        flex-direction: column;
    }

    .Button svg{
        margin-right:0;
    }
}