.Curtain{
    opacity:0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    pointer-events: none;
    transition: opacity 0.3s ease-in;
}
.Show.Curtain{
    opacity:1;
    pointer-events: all;
}

.LoginModalWrapper,
.RegisterModalWrapper,
.ConfirmationModalWrapper,
.ChangePasswordModalWrapper{
    opacity: 0;
    width: 500px;
    background: #F2F2F2;
    padding: 40px 80px 90px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: opacity 0.3s ease-in; 
    z-index: 10001;
    color: #646464;
}

.LoginModalWrapper h1 span,
.RegisterModalWrapper h1 span,
.ConfirmationModalWrapper h1 span,
.ChangePasswordModalWrapper h1 span{
    text-align: center;
    background: #F2F2F2;
    padding: 0 40px;
}

.RegisterModalWrapper {
    padding: 40px 80px 50px;
}

.RegisterModalWrapper h1,
.ConfirmationModalWrapper h1,
.ChangePasswordModalWrapper h1 {
    margin-bottom: 35px;
}

.Show.LoginModalWrapper,
.Show.RegisterModalWrapper,
.Show.ConfirmationModalWrapper,
.Show.ChangePasswordModalWrapper{
    opacity:1;
    pointer-events: all;
}

.ConfirmationMessage{
    text-align:left;
}

.CloseModal{
    width: 30px;
    height: 30px;
    background: #646464;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.CloseModal span{
    width: 2px;
    height: 15px;
    background: #fff;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    left: 14px;
    top: 8px;
}

.CloseModal span:nth-child(1){
    transform: rotate(-45deg);
}

.Heading{
    font-family: 'Roboto Slab', serif;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #646464;
    line-height: 0.1em;
    margin: 50px 0;
    font-size: 1.5em;
}

.InputWrapper{
    background: #fff;
    height: 40px;
    border: solid 1px #b2b2b2;
    border-radius: 1px;
    display: flex;
    align-items: center;
    margin: 20px 0;
    flex: 0 0 47%;
}

.register_form .InputWrapper{
    margin: 10px 0;
}

.InputWrapper svg{
    width:100%;
    max-width:19px;
    max-height: 19px;
    fill:#646464;
}

.InputWrapper input{
    width:100%;
    height: 34px;
    border: none;
    flex: 1;
    outline: none;
    font-size: 1em;
    color: #646464;
    padding-right: 10px;
}

.InputWrapper > div{
    text-align: center;
    flex: 0 0 56px;
}

.InputCheckWrapper{
    display: flex;
    align-items: center;
    flex: 1;
}

.InputCheckWrapper input{
    border: solid 1px #646464;
    outline: none;
    margin-bottom: 1px;
}

.InputCheckWrapper span{
    font-weight: 600;
    margin-left: 10px;
}

.Link{
    color:#82AE2C;
    cursor: pointer;
}

.Flexed{
    display: flex;
}

.SubmitLogin,
.SubmitRegister{
    text-align: center;
    width: 100%;
    height: 50px;
    background: #82AE2C;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    font-weight: 600;
    margin: 40px 0;
    border: none;
    font-size: 1em;
    cursor: pointer;
    outline: none;
}

.Subheading{
    text-align: center;
    display: block;
    margin-bottom: 30px;
}

.RegisterForm .InputWrapper.FlexRight,
.ChangePasswordForm .InputWrapper.FlexRight{
    margin: 10px 0 10px auto;
}

.ChangePasswordButtons .FlexRight{
    margin-left:auto;
}

.RegisterForm input,
.ChangePasswordForm input{
    padding-left:10px;
}

.RegisterForm .InputWrapper,
.ChangePasswordForm .InputWrapper{
    margin: 10px 0;
}

.Margin_b_30{
    margin-bottom:30px;
}

.Margin_b_10{
    margin-bottom:10px;
}

/* Alerts */
.Message{
    width:100%;
    height:0;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;

    transition-property: height;
    transition-duration: 350ms;
    transition-timing-function: cubic-bezier(.165,.84,.44,1);
}

.ShowMessage{
    height:54px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}

.Success.Message{
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.Error.Message{
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}


.Register,
.Login,
.Logout{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.Login svg,
.Logout svg {
    width:22px;
    margin-left: 10px;
}

.Register{
    position: relative;
    margin-right: 15px;
    padding-right: 15px;
}

.Register::after{
    content: '';
    position: absolute;
    top: 1px;
    right: 0;
    width: 2px;
    height: 15px;
    background: #8f8f8f;
}

.ChangePasswordButtons{
    display: flex;
    align-items: center;
}

.ChangePasswordButton{
    text-align: center;
    flex:1;
    height: 50px;
    background: #82AE2C;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    font-weight: 600;
    margin-top: 20px;
    border: none;
    font-size: 1em;
    cursor: pointer;
    outline: none;
    flex: 0 0 47%;
}

.AcceptTermsWrapper{
    display: none;
}

.SpinnerWrapper{
    display: none;
    margin-right:15px;
}

.SpinnerWrapper.Show{
    display: block;
}

@media only screen and (max-width: 1000px) {
    .LoginModalWrapper,
    .RegisterModalWrapper,
    .ChangePasswordModalWrapper{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: unset;
        box-sizing: border-box;
        padding: 30px;
    }

    .LoginModalWrapper h1,
    .RegisterModalWrapper h1,
    .ChangePasswordModalWrapper h1{
        margin-bottom: 20px;
    }

    .Heading{
        font-size: 1.2em;
    }

    .Subheading{
        margin-bottom: 20px;
    }

    .Margin_b_30{
        margin-bottom: 0;
    }
}