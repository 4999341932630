.SwitchButton{
    width: 22px;
    height:14px;
    border-radius: 10px;
    background-color: #c5c5c5;
    position: relative;
    cursor: pointer;

    transition: background-color 0.3s ease-in;
}

.SwitchButton::before{
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;

    transition: transform 0.3s ease-in;
}

.Active.SwitchButton{
    background-color: #82ae2c;
}

.Active.SwitchButton::before{
    transform: translateX(8px);
}